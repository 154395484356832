<template>
  <Layout>
    <Table/>
  </Layout>
</template>

<script>
import Table from "@/components/campaigns/incomplete-campaigns-table";

import Layout from "@/views/layouts/main";

export default {
  components: {
    Layout,
    Table
  },
  data() {
    return {
      data: [],
    };
  },
  created() {
  
  },
  methods: {
  },
};
</script>

<style>
</style>