
<template>
  <div class="card row py-4 px-2">
    <div class="col-12">
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <!-- Table -->
      <div class="table-responsive mb-0">
        <b-table
          :items="campaignData"
          :fields="fields"
          responsive="sm"
          :busy="isBusy"
          :per-page="perPage"
          tbody-tr-class="rowClass"
          :current-page="currentPage"
          @row-clicked="campignSelected"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(company)="row">
            <strong>Name:</strong> {{ row.item.company.name }} <br />
            <strong>Trade Licence:</strong>
            {{ row.item.company.trade_license_number }} <br />
          </template>
          <template #cell(plan)="row">
            <strong>Name:</strong> {{ row.item.plan.name }} <br />
            <strong>No. of vehicles:</strong>
            {{ row.item.plan.no_of_vehicles }} <br />
            <strong>Price:</strong> {{ row.item.plan.price }}
          </template>
          <template #cell(updatedAt)="row">
            {{ moment(row.updatedAt).format("lll") }}
          </template>
          <template #cell(drivers)="row">
            <strong>No. of Driver required:</strong>
            {{ row.item.no_of_drivers_required }}<br />
            <strong>No. of Driver Assigned:</strong
            >{{ row.item.no_of_drivers_assigned }}
          </template>

          <template #cell(createdAt)="row">
            {{ moment(row.createdAt).format("lll") }}
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";

export default {
  data() {
    return {
      campaignData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "plan", sortable: false },
        { key: "plan_price", sortable: false },
        { key: "company", sortable: false },
        { key: "drivers", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.campaignData.length;
    },
  },
  mounted() {},
  created() {
    this.getAllCampigns();
  },
  methods: {
    getAllCampigns() {
      this.isBusy = true;
      const api = campaigns.getUnassigned;
      this.generateAPI(api)
        .then((res) => {
          this.campaignData = res.data.campaigns;
          this.totalRows = this.campaignData.length;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    campignSelected(row) {
     this.$store.dispatch("campaign/selectCampaign", row);
      this.$router.push({ name: "CampaignsDetails", params: { id: row._id } });
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}
.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
